<template>
  <div>
    <div class="row">
      <div class="col-12">
        <q-list bordered class="rounded-borders">
          <q-expansion-item 
            dense dark icon="place" 
            header-class="bg-blue-grey-6 text-white"
            expand-icon-class="text-white"
            label="노드 정보" >
            <q-separator />
            <q-card class="q-pa-sm">
              <div class="row">
                <!-- <div class="col-sm-6 col-lg-3">
                  <c-label-text title="LBLPROCESS" :value="node.processName"></c-label-text>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <c-label-text title="도면" :value="node.pidNm"></c-label-text>
                </div> -->
                <div class="col-sm-6 col-lg-3">
                  <c-label-text title="노드" :value="popupParam.node.nodeTitle + '(' + popupParam.node.nodeNo + ')'"></c-label-text>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <c-label-text title="검토기간" :value="popupParam.node.startStudyDate + '~' + popupParam.node.endStudyDate"></c-label-text>
                </div>
                <div class="col-sm-12 col-lg-6" >
                  <c-label-text :row="2" title="노드설명" :value="popupParam.node.nodeDesc"></c-label-text>
                </div>
                <div class="col-sm-12 col-lg-6" >
                  <c-label-text :row="2" title="설계조건" :value="popupParam.node.designConditions"></c-label-text>
                </div>
                <div class="col-sm-12 col-lg-6" >
                  <c-label-text :row="2" title="작동조건" :value="popupParam.node.operatingConditions"></c-label-text>
                </div>
                <div class="col-sm-12 col-lg-6" >
                  <c-label-text :row="2" title="설계의도" :value="popupParam.node.designIntent"></c-label-text>
                </div>
              </div>
            </q-card>
          </q-expansion-item>
        </q-list>
      </div>
    </div>
    <c-table
      ref="table"
      class="q-mt-sm"
      title="구간별 가이드워드"
      tableId="guideword01"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :hideBottom="true"
      rowKey="ramVariableId"
      :contentsField="contentsField"
      @changeSelection="changeSelection"
      :selection="popupParam.type"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazop-guideword-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
        guideWordItems: [],
        data: [],
        node: {
          ramAssessNodeId: '',  // 노드 일련번호
          ramRiskAssessmentPlanId: '',  // 평가 일련번호
          ramTechniqueCd: '',  // 평가기법 코드_HAZOP, K-PSR 구분(평가계획의 평가기법 코드 그대로 사용)
          processCd: '',  // 단위공정 코드
          psiDiagramId: '',  // 도면 일련번호
          nodeNo: '',  // 노드 번호_수기입력
          nodeTitle: '',  // 노드 타이틀
          nodeDesc: '',  // 노드 설명
          designConditions: '',  // 설계조건
          operatingConditions: '',  // 작동조건
          designIntent: '',  // 설계의도
          startStudyDate: '',  // 검토 시작일
          endStudyDate: '',  // 검토 종료일
          scenarios: [],
        },
        ramRiskAssessmentPlanId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '650px'
      },
      editable: true,
      defaultData: [],
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      this.grid.height = (this.contentHeight - 300) + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.variable.list.url
      // code setting
      let guideword = [];
      this.$_.forEach(this.popupParam.guideWordItems, item => {
        guideword.push({
          name: item.code,
          field: item.code,
          label: item.codeName,
          align: 'center',
          sortable: true,
          selectionAble: true,
          type: 'check',
          true: 'Y',
          false: 'N'
        })
      })
      this.grid.columns = [
        {
          name: 'variableName',
          field: 'variableName',
          label: '변수',
          align: 'center',
          sortable: true,
        },
        {
          name: 'guideword',
          field: 'guideword',
          label: '가이드워드',
          align: 'center',
          child: guideword,
        },
      ];
        
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
        ramRiskAssessmentPlanId: this.popupParam.ramRiskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.defaultData = _result.data;
        this.$_.forEach(this.defaultData, item => {
          let ramVariableGuidewordIds = this.$_.split(item.ramVariableGuidewordIds, ',');
          if (ramVariableGuidewordIds && ramVariableGuidewordIds.length > 0) {
            this.$_.forEach(ramVariableGuidewordIds, ramVariableGuidewordId => {
              let splitData = this.$_.split(ramVariableGuidewordId, '|');
              this.$set(item, splitData[1] + '_use', 'Y')
              this.$set(item, splitData[1] + '_id', splitData[0])
            })
          }
        })

        this.setGridData();
      },);
    },
    setGridData() {
      let selected = [];
      let selectedIndexs = [];
      let dataIndex = 0;
      this.$_.forEach(this.defaultData, item => {
        this.$_.forEach(this.popupParam.guideWordItems, gw => {
          let index = this.$_.findIndex(this.popupParam.data, k => {
            return k.deviationName === item.variableName + ' - ' + gw.codeName
          })
          if (index > -1) {
            // item[gw.code] = 'Y'
            this.$set(item, gw.code, 'Y')
            selectedIndexs.push(dataIndex)
          } else {
            // item[gw.code] = 'N'
            this.$set(item, gw.code, 'N')
          }
        });
        dataIndex++;
      });
      selectedIndexs = this.$_.uniq(selectedIndexs);
      this.$_.forEach(selectedIndexs, index => {
        selected.push(this.defaultData[index])
      })
      this.$refs['table'].selected = selected;
      this.grid.data = this.$_.clone(this.defaultData)
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '위험형태를 지정하세요.', // 위험형태를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
    contentsField(props, col) {
      let returnVal = true;
      if (col.name !== 'variableName') {
        returnVal = props.row[col.name + '_use'] === 'Y'
      }
      return returnVal
    },
    changeSelection(detail) {
      let keys = detail.keys
      if (keys && keys[0] && !detail.added) {
        this.$_.forEach(this.grid.data, (item) => {
          if (item.ramVariableId === keys[0]) {
            this.$_.forEach(this.popupParam.guideWordItems, guideword => {
              // item[guideword.code] = 'N'
              this.$set(item, guideword.code, 'N')
            })
            return false;
          }
        });
      }
    },
  }
};
</script>
